<template>
  <div>
    <AdvertisingArchivedList />
  </div>
</template>

<script>
import AdvertisingArchivedList from "../../../components/Advertising/AdvertisingArchivedList"
export default {
  name: "AdvertisingArchived",
  components: {
    AdvertisingArchivedList
  }
}
</script>

<style scoped>

</style>